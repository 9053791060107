import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';

import Layout from '../components/layout';
// import ScrollAnimation from '../components/scrollAnimation';

const Excursions = ({ data }) => {
  var pageTitle = 'Excursions and Extras';

  return (
    <Layout
      slug="excursions"
      title={pageTitle}
      description="Check out the different excursions and treats we offer!"
    >
      <h1>{pageTitle}</h1>

      <p>
        While our base service remains the same as it always has we are now
        offering some additional excursions and treats! These are perfect to
        switch things up during a longer stays or for pets who need the ultimate
        vacation package. Our excursions are based around creating a mentally
        stimulating environment to keep even the most active pets busy!
      </p>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Sniffy Walks</h2>
            <p>
              Our sniffy walks are a 1 on 1 dog led walk around the property.
              This is a great opportunity for your dog to explore the area while
              keeping their brain busy sorting through the multitudes of smells.
              This is a great option for all ages and abilities of dogs as we go
              as fast or slow as your pup needs! Since these are guided by the
              dog they vary in distance but every walk is guaranteed to be a
              minimum of 15 minutes.
              <p>Please note this activity is weather dependent.</p>
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image1)}
              alt="dog looking at goats"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Agility Session</h2>
            <p>
              Looking to really spice things up during your dogs visit? Have
              them try out an agility session! This is a 1 on 1 sessions where a
              staff member will teach your pup how to master the agility
              obstacles such as jumps, tunnel and the A-frame! This is a great
              option for energetic pets who need a job to focus on as not only
              are they being active but they are exercising their brains as well
              learning all sorts of new skills.
              <p>
                Please note this activity is only available in the summer and
                all dogs must be at least 6+ months to participate.
              </p>
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image2)}
              alt="dog in tunnel"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Gone Swimming</h2>
            <p>
              Is your pup an avid swimmer? This swimming session includes a
              sniffy walk to and from the pond out back as well as a swimming
              session on a long lead! Do they enjoy water retrieving as well?
              Perfect as we also have floating toys handy so they can play a
              game of fetch at the same time!
              <p>
                Please note this activity is weather dependent and only offered
                during the summer.
              </p>
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image3)}
              alt="dog water retrieving"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Pool Party</h2>
            <p>
              If your pup likes water but prefers just to hang out in the
              shallows on a hot day this may be the activity for them! Let your
              pup cool off playing in the pool with a staff member!
              <p>
                Please note this activity is weather dependent and only offered
                during the summer.
              </p>
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image4)}
              alt="dog and pool"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col items-center text-center mb-20">
        <h2>Treats and Eats</h2>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Puzzle Time</h2>
            <p>
              Looking to keep your pets brain busy while they are staying with
              us? A puzzle session is the perfect way to do this! Let them enjoy
              a treat while stimulating their brain. We have many different
              levels and styles of puzzles to suite all pets! Puzzle times are
              available for both cats and dogs.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image5)}
              alt="groomed dog"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section
        id="at-home-nail-trims"
        className="flex flex-col lg:flex-row-reverse lg:items-center mb-20"
      >
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Stuffed Kong</h2>
            <p>
              Let your pup enjoy a fun treat with a stuffed kong! These treats
              are available in both small and large sizes and we have black
              kongs available to ensure the safety of the tough chewers.
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image6)}
              alt="nail trimmers"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Pupsicles</h2>
            <p>
              Help your pup beat the heat with a Pupscile! Pupsicles are frozen
              treats and we have a new flavour every month!
              <p>
                Please note Pupsciles are only available during the summer.{' '}
              </p>
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image7)}
              alt="groomed puppy"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Bedtime Chew</h2>
            <p>
              Spoil your pup at bedtime with a chew! We have a variety of
              natural chews available. Tracheas, pigs ears, bully sticks,
              collagen curls and more! We also have a variety of different
              frozen goodies such as marrow, knuckle and rib bones, lamb ears
              and pork rinds.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image8)}
              alt="dog with chew"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col items-center text-center mb-20">
        <h2>Boarding Packages</h2>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Full Enrichment Package</h2>
            <p>
              The full enrichment package includes all meals being provided in a
              maze or treat ball toy, a puzzle session as well as 2 - 20 minute
              sessions of one on one enrichment activities with a staff member
              such as a sniffy walk, agility course session, scent work and
              more! A picture update through our app is also included
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image9)}
              alt="dog on walk"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Mini Enrichment Package</h2>
            <p>
              The mini enrichment package includes all meals being provided in a
              maze or treat ball toy, a puzzle session as well as 20 minute
              sessions of one on one enrichment activities with a staff member
              such as a sniffy walk, agility course session, scent work and
              more! A picture update through our app is also included.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image10)}
              alt="dog with puzzle"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Tuck In Package</h2>
            <p>
              This package includes a pupsicle, chew or treat of the month as a
              bedtime treat, a 15 minute cuddle session, and a bed/cot upgrade
              for standard suites. A picture update through our app is also
              included
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image11)}
              alt="dog with chew"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "sniffy.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "agility.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "swimming.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 0.8
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    image4: file(relativePath: { eq: "pool.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "puzzle.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "kong.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image7: file(relativePath: { eq: "pupsicle.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image8: file(relativePath: { eq: "chew.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 0.8
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    image9: file(relativePath: { eq: "full.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image10: file(relativePath: { eq: "mason.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image11: file(relativePath: { eq: "tuckin.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default Excursions;
